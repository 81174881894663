import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Badge = makeShortcode("Badge");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hello World 👏`}</h1>
    <Badge mdxType="Badge">introduction</Badge>&nbsp;
    <Badge mdxType="Badge">blog</Badge>
    <p>{`Hi, I'm telling you 'hello' from my new blog. This page is a basic `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file located in the `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` folder and its subsequent subfolders. The choisc of the naming of the subfolders defines the routing of the website. `}</p>
    <h2>{`Coding`}</h2>
    <p>{`What about syntax highlighting for code? The only requirement is to specify the language in your markdown file. Hereafter, a `}<inlineCode parentName="p">{`python`}</inlineCode>{` code block is displayed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import numpy as np

# In[0]: Hello World

x = np.linspace(0,10,2)
y = x**2 + 6
`}</code></pre>
    <h2>{`Math`}</h2>
    <p>{`Ever tought about writing some formulas? We've got you covered. Using `}<inlineCode parentName="p">{`Katex`}</inlineCode>{`, formulas (both `}<inlineCode parentName="p">{`inline`}</inlineCode>{` and in `}<inlineCode parentName="p">{`display`}</inlineCode>{`) written down in `}<span parentName="p" {...{
        "className": "inlineMath"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`LaTeX`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\LaTeX`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.89883em",
                  "verticalAlign": "-0.2155em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord textrm"
                }}>{`L`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "-0.36em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.68333em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-2.904999em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "2.7em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord"
                        }}><span parentName="span" {...{
                            "className": "mord textrm mtight sizing reset-size6 size3"
                          }}>{`A`}</span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "-0.15em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord textrm"
                  }}>{`T`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "-0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.46782999999999997em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.7845em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord textrm"
                            }}>{`E`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2155em"
                        }
                      }}><span parentName="span"></span></span></span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "-0.125em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord textrm"
                  }}>{`X`}</span></span></span></span></span></span></span>{` are getting converted to `}<inlineCode parentName="p">{`html`}</inlineCode>{`. `}</p>
    <p>{`If `}<span parentName="p" {...{
        "className": "inlineMath"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`3`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_{1} = 3`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`3`}</span></span></span></span></span>{` and `}<span parentName="p" {...{
        "className": "inlineMath"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`4`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_{2} = 4`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.58056em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.30110799999999993em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.5500000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`4`}</span></span></span></span></span>{`, then:`}</p>
    <div {...{
      "className": "math"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><msubsup parentName="mrow"><mi parentName="msubsup">{`x`}</mi><mn parentName="msubsup">{`1`}</mn><mn parentName="msubsup">{`2`}</mn></msubsup><mo parentName="mrow">{`+`}</mo><msubsup parentName="mrow"><mi parentName="msubsup">{`x`}</mi><mn parentName="msubsup">{`2`}</mn><mn parentName="msubsup">{`2`}</mn></msubsup><mo parentName="mrow">{`=`}</mo><msup parentName="mrow"><mn parentName="msup">{`5`}</mn><mn parentName="msup">{`2`}</mn></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_{1}^2 + x_{2}^2 = 5^2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1111079999999998em",
                  "verticalAlign": "-0.247em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4530000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.247em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1111079999999998em",
                  "verticalAlign": "-0.247em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4530000000000003em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.247em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8641079999999999em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`5`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8641079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.113em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`Goodbye 👋`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      